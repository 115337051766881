import { useRecoilValue } from 'recoil';
import { completePrintHarvestModalAtoms } from '../../complete-print-harvest-modal.atoms';
import { EvaluatePartContent } from '../step-evaluate-part/evaluate-part-content';
import { CompleteChecklistContent } from '../step-complete-checklist/complete-checklist-content';
import { JobFailedContent } from '../step-job-failed/job-failed-content';
import { MachineOfflineContent } from '../step-machine-offline/machine-offline-content';
import { PrepareMachineContent } from '../step-prepare-machine/step-prepare-machine-content';
import { PrintRFIDTagContent } from '../step-print-rfid-tag/step-print-rfid-tag-content';
import { printHarvestSteps } from '../../complete-print-harvest-modal.constants';

export const CompletePrintHarvestContent = () => {
  const step = useRecoilValue(completePrintHarvestModalAtoms.step);

  switch (step) {
    case printHarvestSteps.evaluatePart:
      return <EvaluatePartContent />;

    case printHarvestSteps.completeChecklist:
      return <CompleteChecklistContent />;

    case printHarvestSteps.prepareMachine:
      return <PrepareMachineContent />;

    case printHarvestSteps.printRfigTag:
      return <PrintRFIDTagContent />;

    case printHarvestSteps.jobFailed:
      return <JobFailedContent />;

    case printHarvestSteps.machineOffline:
      return <MachineOfflineContent />;

    default:
      return null;
  }
};
