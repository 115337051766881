import React from 'react';
import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../../../../jobs/components/modals/modals.constants';
import { repairJobModalSteps } from '../../../../repair-job-modal.types';
import { repairJobModalModalAtoms } from '../../../../repair-job-modal.atom';

export const PrepareMachineFooter = () => {
  const material = useRecoilValue(repairJobModalModalAtoms.material);
  const spoolWeight = useRecoilValue(repairJobModalModalAtoms.spoolWeight);
  const setStep = useSetRecoilState(repairJobModalModalAtoms.step);

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep(repairJobModalSteps.confirmUsedInventory);
        }}
      />
      <Button
        disabled={!material || !spoolWeight}
        size={fullScreenModalFooterButtonSize}
        text="Confirm"
        onClick={() => {
          setStep(repairJobModalSteps.confirmCompleteRepair);
        }}
      />
    </>
  );
};
