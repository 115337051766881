import { Button } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ConfirmUsedInventoryFooter } from './components/confirm-used-inventory-footer';
import { NewRepairFooter } from './components/new-repair-footer';
import { FooterProps } from './footer.types';
import { ConfirmCompleteRepairFooter } from './components/confirm-complete-repair-footer';
import { fullScreenModalFooterButtonSize } from '../../../../../jobs/components/modals/modals.constants';
import { repairJobModalSteps } from '../../repair-job-modal.types';
import { PrepareMachineFooter } from './components/prepare-machine-footer';
import {
  repairJobModalModalAtoms,
  repairJobModalModalSelectors,
} from '../../repair-job-modal.atom';

export const Footer = (props: FooterProps) => {
  const { handleCloseModal } = props;

  const [step, setStep] = useRecoilState(repairJobModalModalAtoms.step);
  const [job, setJob] = useRecoilState(repairJobModalModalAtoms.job);
  const notes = useRecoilValue(repairJobModalModalAtoms.notes);
  const repairType = useRecoilValue(repairJobModalModalAtoms.repairType);
  const selectedIssues = useRecoilValue(
    repairJobModalModalAtoms.selectedIssues,
  );
  const inventoryRequests = useRecoilValue(
    repairJobModalModalAtoms.inventoryRequests,
  );
  const isMachineWithComponentLifespans = useRecoilValue(
    repairJobModalModalSelectors.isMachineWithComponentLifespans,
  );
  const setUpdateOnClose = useSetRecoilState(
    repairJobModalModalAtoms.updateOnClose,
  );

  switch (step) {
    case repairJobModalSteps.newRepair:
      return (
        <NewRepairFooter
          isMachineWithComponentLifespans={isMachineWithComponentLifespans}
          machine={job?.machine}
          notes={notes}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setJob={setJob}
          setStep={setStep}
          setUpdateOnClose={setUpdateOnClose}
        />
      );

    case repairJobModalSteps.confirmUsedInventory:
      return (
        <ConfirmUsedInventoryFooter
          inventoryRequests={inventoryRequests}
          setStep={setStep}
        />
      );

    case repairJobModalSteps.prepareMachine:
      return <PrepareMachineFooter />;

    case repairJobModalSteps.confirmCompleteRepair:
      return (
        <ConfirmCompleteRepairFooter
          inventoryRequests={inventoryRequests}
          jobId={job.id}
          notes={notes}
          selectedIssues={selectedIssues}
          setStep={setStep}
          setUpdateOnClose={setUpdateOnClose}
        />
      );

    case repairJobModalSteps.repairSummary:
      return (
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Done"
          onClick={handleCloseModal}
        />
      );

    default:
      return null;
  }
};
