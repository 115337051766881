import { JobDetails } from '../../../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { OverviewMachine } from '../../../../../../api/machines/routes/get-overview/get-overview.types';

export const getMachine = (args: {
  startingJobData: JobDetails;
  startingMachineData: OverviewMachine;
}) => {
  const { startingJobData, startingMachineData } = args;

  const machine: Pick<
    OverviewMachine,
    'id' | 'name' | 'type' | 'openIssues' | 'material'
  > = {
    id:
      startingMachineData?.id ||
      startingJobData?.machine?.id ||
      startingJobData?.machineId,
    material:
      startingMachineData?.material || startingJobData?.machine?.material,

    name:
      startingMachineData?.name ||
      startingJobData?.machine?.name ||
      startingJobData?.machineName,
    /*
     * combine the machine open issues with the repair job selected issues.
     *
     * when the worker created the repair job, machineIssues = [];
     * when the worker completed the repair job, machineIssues has the previous selection and openIssues has the active open issues
     * */
    openIssues: [
      ...(startingJobData?.machineIssues || []),
      ...(startingMachineData?.openIssues ||
        startingJobData?.machine?.openIssues ||
        []),
    ],
    type:
      startingMachineData?.type ||
      startingJobData?.machine?.type ||
      startingJobData?.machineType,
  };

  return machine;
};
