import React, { FC } from 'react';
import { Box, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { getPrintRfidUrl } from '../../../../../../../../../components/common/custom-data-cells/rfid-data-cell';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { FileToDownload } from '../../../../../../../../../hooks/use-download/hooks/use-handle-download-request/use-handle-download-request.types';
import { useDownloadButton } from '../../../../../../../../../hooks/use-download-button';
import { downloadToastText } from '../../../../../../../../../hooks/use-download-with-small-toast';

export const PrintRFIDTagContent: FC = () => {
  const job = useRecoilValue(completePrintHarvestModalAtoms.job);

  const rfidFile: FileToDownload = {
    url: getPrintRfidUrl({
      partId: job.part?.id,
      stockId: job.stock?.id,
    }),
  };
  const {
    DownloadButton: DownloadTagButton,
    DownloadStatusToast: DownloadTagToast,
  } = useDownloadButton({
    ...downloadToastText.rfidTag,
    download: rfidFile,
  });

  return (
    <Stack gap="4x" justifyItems="stretch">
      <Box>
        {/* Toasts must be above section title to achieve desired positioning. */}
        <DownloadTagToast position="bottom-end" />
        <ModalContentHeading titleText="Print RFID tag" />
      </Box>

      <DownloadTagButton text="Print tag" />
    </Stack>
  );
};
