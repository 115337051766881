import { useRecoilValue } from 'recoil';
import { completePrintHarvestModalAtoms } from '../../complete-print-harvest-modal.atoms';
import { EvaluatePartFooter } from '../step-evaluate-part/evaluate-part-footer';
import { CompleteChecklistFooter } from '../step-complete-checklist/complete-checklist-footer';
import { CompletePrintHarvestFooterProps } from './complete-print-harvest-footer.types';
import { JobFailedFooter } from '../step-job-failed/job-failed-footer';
import { MachineOfflineFooter } from '../step-machine-offline/machine-offline-footer';
import { PrepareMachineFooter } from '../step-prepare-machine/step-prepare-machine-footer';
import { PrintRfidTagFooter } from '../step-print-rfid-tag/step-print-rfid-tag-footer';
import { printHarvestSteps } from '../../complete-print-harvest-modal.constants';

export const CompletePrintFooter = (props: CompletePrintHarvestFooterProps) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(completePrintHarvestModalAtoms.step);

  switch (step) {
    case printHarvestSteps.evaluatePart:
      return <EvaluatePartFooter handleCloseModal={handleCloseModal} />;

    case printHarvestSteps.completeChecklist:
      return <CompleteChecklistFooter handleCloseModal={handleCloseModal} />;

    case printHarvestSteps.prepareMachine:
      return <PrepareMachineFooter handleCloseModal={handleCloseModal} />;

    case printHarvestSteps.printRfigTag:
      return <PrintRfidTagFooter handleCloseModal={handleCloseModal} />;

    case printHarvestSteps.jobFailed:
      return <JobFailedFooter handleCloseModal={handleCloseModal} />;

    case printHarvestSteps.machineOffline:
      return <MachineOfflineFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
