import { jobSteps, jobTypeOptions } from './options';

/* eslint-disable   sort-keys-fix/sort-keys-fix */
export const machineTypes = {
  p1p: 'P1P',
  creality: 'Ender 5+',
  tumbler: 'Tumbler',
  ultimaker: 'Ultimaker',
} as const;

export const bambuMachineTypes: MachineType[] = [machineTypes.p1p];

export const machineIssueSources = {
  manual: 'Manual',
  print: jobSteps.print,
  printQc: `${jobSteps.print} ${jobTypeOptions.qc}`,
} as const;

export type MachineType = typeof machineTypes[keyof typeof machineTypes];

export const printerTypes: MachineType[] = Object.values(machineTypes);

export const newPartModalMachineTypes = [
  machineTypes.p1p,
  machineTypes.creality,
];

export const machineStatuses = {
  archived: 'Archived',
  inProgress: 'In progress',
  manual: 'Manual',
  offline: 'Offline',
  ready: 'Ready',
  stopped: 'Stopped',
} as const;

export type MachineStatus =
  typeof machineStatuses[keyof typeof machineStatuses];

export const isBambuMachine = (machineType?: MachineType) => {
  return machineType === machineTypes.p1p;
};
