import React, { FC } from 'react';
import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { jobsApi, machinesApi } from '../../../../../../../../api';
import { ConfirmCompleteRepairFooterProps } from './confirm-complete-repair-footer.types';
import { fullScreenModalFooterButtonSize } from '../../../../../../../jobs/components/modals/modals.constants';
import { repairJobModalSteps } from '../../../../repair-job-modal.types';
import {
  repairJobModalModalAtoms,
  repairJobModalModalSelectors,
} from '../../../../repair-job-modal.atom';

export const ConfirmCompleteRepairFooter: FC<
  ConfirmCompleteRepairFooterProps
> = (props) => {
  const {
    inventoryRequests,
    jobId,
    notes,
    selectedIssues,
    setStep,
    setUpdateOnClose,
  } = props;
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const isBambuMachine = useRecoilValue(
    repairJobModalModalSelectors.isBambuMachine,
  );
  const material = useRecoilValue(repairJobModalModalAtoms.material);
  const spoolWeight = useRecoilValue(repairJobModalModalAtoms.spoolWeight);

  const handleCompleteRepairJob = async () => {
    try {
      showLoading();

      const issueIds = selectedIssues.map(({ id }) => {
        return id;
      });

      await jobsApi.updateJob({
        inventoryRequests,
        issues: issueIds,
        jobId,
        notes,
      });

      await machinesApi.completeRepair({ jobId, material, spoolWeight });

      setUpdateOnClose(false);
      setStep(repairJobModalSteps.repairSummary);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to complete repair job.',
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep(
            isBambuMachine
              ? repairJobModalSteps.prepareMachine
              : repairJobModalSteps.confirmUsedInventory,
          );
        }}
      />
      <Button
        disabled={!selectedIssues?.length}
        size={fullScreenModalFooterButtonSize}
        text="Complete Repair"
        onClick={handleCompleteRepairJob}
      />
    </>
  );
};
