import { MachineRecord } from '../../machines.types';
import { OverviewMachine } from '../../../../api/machines/routes';

type MachinesToUpdate = MachineRecord | OverviewMachine;

export interface ChangeSpoolModalProps {
  machinesToUpdate: MachinesToUpdate[];
  onClose: () => void;
  onSuccess?: () => Promise<void>;
}

export interface ChangeSpoolMachinesTooltipProps {
  machinesToUpdate: MachinesToUpdate[];
}

export const changeSpoolOperationType = {
  new: 'new',
  used: 'used',
} as const;
