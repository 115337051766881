import { Button } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ButtonVariant } from 'gantri-components/dist/components/button/button.types';
import { text } from './complete-checklist-footer.constants';
import { CompleteChecklistFooterProps } from './complete-checklist-footer.types';
import { checkAgainstFailingChecklistItems } from '../../../../../helpers/check-against-failing-checklist-items';
import { usePassOrFailJob } from './hooks/use-pass-or-fail-job';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { CompleteChecklistButtonWrapper } from '../../../../../common/job-checklist/components/complete-checklist-button-wrapper';
import { isBambuMachine } from '../../../../../../../../../constants/machines';
import { machinesApi } from '../../../../../../../../../api';
import { printHarvestSteps } from '../../../complete-print-harvest-modal.constants';
import { useNotification } from '../../../../../../../../../hooks/useNotification';

export const CompleteChecklistFooter = (
  props: CompleteChecklistFooterProps,
) => {
  const { handleCloseModal } = props;

  const { notifyError } = useNotification();
  const job = useRecoilValue(completePrintHarvestModalAtoms.job);
  const setOfflineForMaintenance = useSetRecoilState(
    completePrintHarvestModalAtoms.offlineForMaintenance,
  );
  const checklist = useRecoilValue(completePrintHarvestModalAtoms.checklist);

  const [isChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    completePrintHarvestModalAtoms.isChecklistReviewed,
  );

  const setStep = useSetRecoilState(completePrintHarvestModalAtoms.step);
  const isBambu = isBambuMachine(job?.machine?.type || job?.machineType);

  const shouldTakeMachineOffline = checkAgainstFailingChecklistItems(
    checklist,
    (item) => {
      return item.failStatusShouldTakeMachineOffline;
    },
  );

  const partFailed = checkAgainstFailingChecklistItems(checklist, (item) => {
    return item.failStatusShouldFailPart;
  });

  const { onFail, onPass, processing } = usePassOrFailJob({
    handleCloseModal,
    job,
  });

  /** Non-bambu machines */
  const onCompleteOtherMachinesChecklist = async () => {
    if (partFailed) {
      return onFail();
    }

    await onPass();
  };

  const onCompleteBambuMachinesChecklist = async () => {
    /**
     * Scenario 2: failure logic the machine will be moved to offline.
     * call onFail flow, that will fail the job and move it to JOB_FAILED step
     * */
    if (shouldTakeMachineOffline) {
      return onFail();
    }

    const { error, offlineForMaintenance, success } =
      await machinesApi.maintenanceChecker({
        jobId: job.id,
        machineId: job.machine?.id,
      });

    if (!success) {
      return notifyError(error);
    }

    // saved in the state to be used in the PRINT_RFID_TAG step
    setOfflineForMaintenance(offlineForMaintenance);

    /**
     * Scenario 3: with failing parts and based of the job duration the machine will be moved to maintenance.
     * call onFail flow, that will fail the job and move it to JOB_FAILED step
     * */
    if (offlineForMaintenance && partFailed) {
      return onFail();
    }

    /**
     * Scenario 4: without failing parts and based of the job duration the machine will be moved to maintenance.
     * move it to PRINT_RFID_TAG step
     * */
    if (offlineForMaintenance && !partFailed) {
      setStep(printHarvestSteps.printRfigTag);

      return;
    }

    /**
     * Scenario 5: based of the job duration the machine will not be moved to maintenance,
     * with failing part and shouldTakeMachineOffline = false or without failing parts.
     * move it to PREPARE_MACHINE step to set the spool weight and material
     * */
    setStep(printHarvestSteps.prepareMachine);
  };

  const onCompleteChecklist = async () => {
    /**
     * Scenario 1: No Bambu machines should follow the existing workflow
     * */
    if (!isBambu) {
      return onCompleteOtherMachinesChecklist();
    }

    await onCompleteBambuMachinesChecklist();
  };

  const nextBtnVariant: ButtonVariant =
    partFailed || shouldTakeMachineOffline ? 'primaryAlert' : 'primary';

  const onBack = () => {
    if (isChecklistReviewed) {
      setIsChecklistReviewed(false);
    }

    setStep(printHarvestSteps.evaluatePart);
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={onBack}
      />
      <CompleteChecklistButtonWrapper checklist={checklist}>
        <Button
          data-button-variant={nextBtnVariant}
          disabled={!isChecklistReviewed}
          processing={processing}
          size={fullScreenModalFooterButtonSize}
          text={partFailed ? text.failJobButton : text.passJobButton(job.type)}
          variant={nextBtnVariant}
          onClick={onCompleteChecklist}
        />
      </CompleteChecklistButtonWrapper>
    </>
  );
};
