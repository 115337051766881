import { JobDetails } from '../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { OverviewMachine } from '../../../../api/machines/routes/get-overview/get-overview.types';

export const repairJobModalSteps = {
  confirmCompleteRepair: 'CONFIRM_COMPLETE_REPAIR',
  confirmUsedInventory: 'CONFIRM_USED_INVENTORY',
  newRepair: 'NEW_REPAIR',
  prepareMachine: 'PREPARE_MACHINE',
  repairSummary: 'REPAIR_SUMMARY',
} as const;

export type RepairJobModalProps = WithJobOrMachineData & {
  onClose: () => void;
  onJobUpdated: () => Promise<void>;
};

type WithJobOrMachineData = WithJobData | WithMachineData;

interface WithJobData {
  job: JobDetails;
  machine?: never;
}

interface WithMachineData {
  job?: never;
  machine: OverviewMachine;
}

export type RepairJobModalStep =
  typeof repairJobModalSteps[keyof typeof repairJobModalSteps];
