import { Conditional, Icon, Radio, Stack, Typography } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMemo } from 'react';
import { startPrintPrintJobModalAtoms } from '../../../../../start-print-print-job-modal.atoms';
import { getInventoryRequestsForInventory } from './helpers/get-inventory-requests-for-inventory';
import { NewSpoolInventoryPropsDef } from './new-spool-inventory.types';
import { Inventory } from '../../../../../../../../../../../../api/inventories/routes/fetch-all/fetch-all-inventories.types';
import {
  isOpaqueMaterial,
  materials,
} from '../../../../../../../../../../../../constants/parts';

export const NewSpoolInventory = (props: NewSpoolInventoryPropsDef) => {
  const { isLoading, material, materialInventories } = props;
  const isOpaque = isOpaqueMaterial(material);

  const setInventoryRequests = useSetRecoilState(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );

  const [materialChanged, setMaterialChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.materialChanged,
  );

  const [inventorySelected, setInventorySelected] = useRecoilState(
    startPrintPrintJobModalAtoms.inventorySelected,
  );

  const spoolInventoryDetails = useRecoilValue(
    startPrintPrintJobModalAtoms.spoolInventoryDetails,
  );

  const getInventoriesIdsToShow = () => {
    if (isOpaque) {
      return [
        spoolInventoryDetails[materials.fabbOpaque].id,
        spoolInventoryDetails[materials.polymakerOpaque].id,
      ];
    }

    return [
      spoolInventoryDetails[materials.polymakerTranslucent].id,
      spoolInventoryDetails[materials.fabbTranslucent].id,
    ];
  };

  const inventoryItems = useMemo(() => {
    if (materialInventories) {
      // ? indexed in display order
      const inventoryIdsToShow = getInventoriesIdsToShow();

      return inventoryIdsToShow.map((idToShow) => {
        const matchingInventory = materialInventories.find(({ id }) => {
          return id === idToShow;
        });

        const { description, material } = Object.values(
          spoolInventoryDetails,
        ).find(({ id }) => {
          return id === idToShow;
        });

        const updatedInventory: Inventory = {
          ...matchingInventory,
          description,
          material,
        };

        return updatedInventory;
      });
    }

    return [];
  }, [materialInventories]);

  const onSelected = (id: string) => {
    const inventory = inventoryItems.find((inventory) => {
      return String(inventory.id) === id;
    });

    const inventoryRequests = getInventoryRequestsForInventory(
      inventory,
      spoolInventoryDetails?.[inventory.material]?.need || 0,
    );

    // TODO: We should consider replacing all of these global states inside the job dialogs
    //  with Formik's internal state.
    setInventoryRequests(inventoryRequests);
    setInventorySelected(inventory);
    setMaterialChanged(inventory.material);
  };

  return (
    <Stack gap=".5x">
      <Typography text="Select material added:" variant="h6" />

      <Stack gap="x">
        <Conditional
          key="is-loading-inventories"
          condition={isLoading}
          Fallback={
            <>
              {inventoryItems.map((inventory, index) => {
                const { id, material: inventoryMaterial, name } = inventory;

                // ? The inventory request only ever has one index
                const value = inventoryMaterial === materialChanged ? id : null;

                return (
                  <Radio
                    key={index}
                    groupValue={id}
                    labelText={inventoryMaterial}
                    name={name}
                    value={value}
                    onSelected={onSelected}
                  />
                );
              })}

              <Conditional condition={!!materialChanged && !!inventorySelected}>
                <Typography
                  color="t2"
                  text={`A transaction of ${spoolInventoryDetails[materialChanged]?.need}kg of - #${inventorySelected?.id} - ${inventorySelected?.name} will be recorded.`}
                />
              </Conditional>
            </>
          }
        >
          <Icon color="link" name="animated:loader" size="2.4rem" />
        </Conditional>
      </Stack>
    </Stack>
  );
};
