import { Button } from 'gantri-components';
import { jobsApi } from '../../../../../../../../api';
import {
  jobStatuses,
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { CompletePrintTransferFooterProps } from './complete-print-transfer-footer.types';

export const CompletePrintTransferFooter = (
  props: CompletePrintTransferFooterProps,
) => {
  const { job, onClose, onJobUpdated } = props;

  const { notify, notifyAxiosError, notifyError, processing, setProcessing } =
    useNotification();

  /** basically will close the modal (onClose) and refresh the job data (onJobUpdated) */
  const onFinishAction = async () => {
    await onJobUpdated();

    onClose();
  };

  const handleCompleteJob = async () => {
    try {
      setProcessing(true);

      const { isFleetManagement } =
        job.status === jobStatuses.ready
          ? await jobsApi.startJob({ jobId: job.id })
          : job;

      if (isFleetManagement) {
        notify(
          `${
            jobSteps.print
          } ${jobTypeOptions.transfer.toLowerCase()} job started successfully.`,
        );

        return await onFinishAction();
      }

      await jobsApi.completeJob({ jobId: job.id });

      notify(
        `${
          jobSteps.print
        } ${jobTypeOptions.transfer.toLowerCase()} job completed successfully.`,
      );

      await onFinishAction();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: `Unable to complete ${jobSteps.print.toLowerCase()} ${jobTypeOptions.transfer.toLowerCase()} job.`,
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Button
      processing={processing}
      size={fullScreenModalFooterButtonSize}
      text="Complete job"
      onClick={handleCompleteJob}
    />
  );
};
