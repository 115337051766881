import { atom, selector } from 'recoil';
import { MachineRepairType } from '../../../../constants/options/options.types';
import { JobDetails } from '../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { RepairJobModalStep } from './repair-job-modal.types';
import { OpenIssue } from '../../../../api/machines/machines.types';
import { Material } from '../../../../constants/parts';
import { InventoryRequest } from '../../../../api/jobs/jobs.types';
import { OverviewMachine } from '../../../../api/machines/routes';
import {
  isBambuMachine as isBambuMachineFn,
  machineTypes,
} from '../../../../constants/machines';
import { jobStatuses } from '../../../../constants/options';

export const atomKeyPrefix = 'REPAIR_JOB_MODAL';

export const repairJobModalModalAtoms = {
  inventoryRequests: atom<InventoryRequest[]>({
    default: [],
    key: `${atomKeyPrefix}-inventory-requests`,
  }),
  job: atom<JobDetails>({
    default: null,
    key: `${atomKeyPrefix}-job`,
  }),
  machine: atom<OverviewMachine>({
    default: null,
    key: `${atomKeyPrefix}-machine`,
  }),
  material: atom<Material>({
    default: null,
    key: `${atomKeyPrefix}-material`,
  }),
  notes: atom<string>({
    default: '',
    key: `${atomKeyPrefix}-notes`,
  }),
  repairType: atom<MachineRepairType>({
    default: null,
    key: `${atomKeyPrefix}-repair-type`,
  }),
  selectedIssues: atom<OpenIssue[]>({
    default: [],
    key: `${atomKeyPrefix}-selected-issues`,
  }),
  spoolWeight: atom<number>({
    default: null,
    key: `${atomKeyPrefix}-spool-weight`,
  }),
  step: atom<RepairJobModalStep>({
    default: null,
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: null,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};

const isBambuMachine = selector<boolean>({
  get: ({ get }) => {
    const machine = get(repairJobModalModalAtoms.machine);

    return isBambuMachineFn(machine?.type);
  },
  key: `${atomKeyPrefix}-is-bambu-machine`,
});

const isMachineWithComponentLifespans = selector<boolean>({
  get: ({ get }) => {
    const machine = get(repairJobModalModalAtoms.machine);

    return machineTypes.creality === machine?.type;
  },
  key: `${atomKeyPrefix}-is-machine-with-component-lifespans`,
});

const isJobInProgress = selector<boolean>({
  get: ({ get }) => {
    const job = get(repairJobModalModalAtoms.job);

    return job?.status === jobStatuses.inProgress;
  },
  key: `${atomKeyPrefix}-is-job-in-progress`,
});

const openIssues = selector<OpenIssue[]>({
  get: ({ get }) => {
    const machine = get(repairJobModalModalAtoms.machine);
    const isInProgress = get(isJobInProgress);

    return machine?.openIssues.filter(({ jobId }) => {
      return !jobId || isInProgress;
    });
  },
  key: `${atomKeyPrefix}-open-issues`,
});

export const repairJobModalModalSelectors = {
  isBambuMachine,
  isJobInProgress,
  isMachineWithComponentLifespans,
  openIssues,
};
