import {
  Button,
  Cell,
  Conditional,
  Flex,
  FormikInput,
  Icon,
  Modal,
  RadioList,
  SearchField,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Form, Formik, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import {
  ChangeSpoolModalProps,
  changeSpoolOperationType,
} from './change-spool-modal.types';
import { ChangeSpoolFormDef, ChangeSpoolSchema } from './change-spool.schema';
import { materialsFilterOptions } from '../../../../constants/parts';
import { ChangeSpoolMachinesTooltip } from './change-spool-machines-tooltip';
import { useChangeMachineSpool } from '../../../../api/machines/routes/change-spool';
import { useFetchConfigurations } from '../../../../api/configurations';

const initialValues: ChangeSpoolFormDef = {
  material: null,
  spoolWeight: null,
  state: changeSpoolOperationType.used, // new or used spool
};

export const ChangeSpoolModal = (props: ChangeSpoolModalProps) => {
  const { machinesToUpdate, onClose, onSuccess } = props;
  const isBulkUpdate = machinesToUpdate.length > 1;
  // there is no need to have a global state (recoil) to store this data since the query have an infinity staleTime cache
  const { data } = useFetchConfigurations({
    fetchArgs: { names: ['change_spool'] },
  });

  const inventoryTransactionSpoolDetails = data?.change_spool || {};

  const { isLoading, onChangeSpool } = useChangeMachineSpool({
    onSuccess: async () => {
      onClose?.();
      await onSuccess?.();
    },
  });

  const onConfirm = async (values) => {
    await onChangeSpool({
      addedType: values.state,
      machineIds: machinesToUpdate.map(({ id }) => {
        return id;
      }),
      material: values.material,
      spoolWeight: values.spoolWeight,
    });
  };

  const materialChoices = useMemo(() => {
    return materialsFilterOptions.map((value) => {
      return {
        label: value,
        value,
      };
    });
  }, []);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        state: isBulkUpdate
          ? changeSpoolOperationType.new
          : changeSpoolOperationType.used,
      }}
      validateOnMount
      validationSchema={ChangeSpoolSchema}
      onSubmit={onConfirm}
    >
      {({ isValid, setFieldValue, values }: FormikProps<any>) => {
        const isNew = values.state === changeSpoolOperationType.new;
        const details = inventoryTransactionSpoolDetails[values.material];

        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid || isLoading}
                    processing={isLoading}
                    size="large"
                    text="Confirm Change"
                    type="submit"
                  />
                </>
              }
              header="Change Spool"
              width={{ lg: '51rem', md: '100%' }}
              onClose={onClose}
            >
              <Stack gap="3x">
                <Conditional condition={machinesToUpdate.length > 1}>
                  <Flex alignItems="center" gap=".5x">
                    <Typography
                      htmlText={`Provide spool change details for: <strong>${machinesToUpdate.length} machines</strong>.`}
                    />

                    <Tooltip
                      Component={
                        <ChangeSpoolMachinesTooltip
                          machinesToUpdate={machinesToUpdate}
                        />
                      }
                    >
                      <Button
                        icon={<Icon name="ui-control:lines_three_24" />}
                        iconLocation="right"
                        text="View list"
                        variant="ghost"
                      />
                    </Tooltip>
                  </Flex>
                </Conditional>

                <FormikInput
                  Field={
                    <SearchField
                      items={materialChoices}
                      labelPosition="top"
                      labelText="Spool material"
                      maxHeight={400}
                      placeholder="Select material"
                    />
                  }
                  name="material"
                  required
                />

                <FormikInput
                  labelText="Current Spool Weight"
                  name="spoolWeight"
                  placeholder="Input weight"
                  required
                  rightIcon={<Typography color="t2" text="g" variant="p2" />}
                  type="number"
                />

                <Conditional condition={!isBulkUpdate}>
                  <Cell rowGap="x">
                    <Typography
                      color="t1"
                      text="Used or new spool(s) added?"
                      variant="h6"
                    />

                    <FormikInput
                      Field={
                        <RadioList
                          gridProps={{ columns: 2, gap: 'x' }}
                          items={[
                            {
                              labelHtmlText:
                                '<strong>Used</strong> spool(s) added',
                              value: changeSpoolOperationType.used,
                            },
                            {
                              labelHtmlText:
                                '<strong>New</strong> spool(s) added',
                              value: changeSpoolOperationType.new,
                            },
                          ]}
                          showAsTiles
                          value={values.state}
                          onSelected={async (value) => {
                            await setFieldValue('state', value);
                          }}
                        />
                      }
                      fieldVariant="standard"
                      name="state"
                    />
                  </Cell>
                </Conditional>

                <Conditional condition={isNew || isBulkUpdate}>
                  <Cell>
                    <Typography
                      color="t1"
                      paddingBottom=".5x"
                      text="Inventory Transaction Details"
                      variant="h6"
                    />

                    <Conditional
                      condition={!!details}
                      Fallback={<Typography text="-" />}
                    >
                      <Typography
                        text={`A transaction of ${details?.weight}kg ${
                          isBulkUpdate
                            ? 'of inventory per machine will be recorded. '
                            : 'of inventory will be recorded.'
                        }`}
                      />

                      <Typography
                        color="t2"
                        text={`#${details?.id}: ${details?.name}.`}
                      />
                    </Conditional>
                  </Cell>
                </Conditional>
              </Stack>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
