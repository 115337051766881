import { isEqual } from 'lodash';
import { DependencyList, useEffect, useMemo, useRef } from 'react';
import {
  Snapshot,
  useGotoRecoilSnapshot,
  useRecoilCallback,
  useRecoilSnapshot,
  useRecoilState,
} from 'recoil';
import { DEFAULT_PAGING_SIZE } from '../../../../../constants/common';
import { usePagingState } from '../../../../../hooks';
import { useResetRecoilAtoms } from '../../../../../hooks/use-reset-recoil-atoms';
import { notificationAtoms } from '../../../../notification/notification.atoms';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
  sortByFallbackDefaultValue,
} from './use-table-filters.atoms';
import {
  DropFirst,
  PurifiedEndpointArgs,
  SortBy,
  UseTableFiltersProps,
} from './use-table-filters.types';

/**
 * A standardized hook for controlling any filters, search, and sort-by options on a table.
 *
 * @example
 * const { ... } = useTableFilters<
 *   RecordType,
 *   EndpointArgs,
 *   SortingField,
 * >({ ... })
 *
 */
export const useTableFilters = <
  RecordType = any,
  EndpointArgs = Record<string, any>,
  SortingField = string,
>(
  props: UseTableFiltersProps<EndpointArgs, SortingField>,
  fetchDependencies: DependencyList = [],
) => {
  const {
    excludeFromActiveFiltersCount,
    fetchRequest,
    filtersContent,
    modalFiltersDetails,
    pageName,
    pageSize,
    searchFilterDetails,
    sortByFilterDetails,
    sortOptions,
    startingPage,
  } = props;

  const {
    currentPage,
    itemsPerPage: count,
    pages,
    records,
    setCurrentPage,
    setRecords,
    setTotalCount,
    totalCount,
  } = usePagingState<RecordType>(pageSize || DEFAULT_PAGING_SIZE, startingPage);

  const memoizedFetchDependencies = useMemo(() => {
    return fetchDependencies;
  }, fetchDependencies);

  // RESTORABLE SNAPSHOT
  const startingSnapshot = useRecoilSnapshot();
  const savedSnapshotRef = useRef<Snapshot>(startingSnapshot);
  const savedSnapshot = savedSnapshotRef.current;

  const handleFetchRequest = useRecoilCallback(({ snapshot }) => {
    const searchValue = searchFilterDetails
      ? snapshot.getLoadable(searchFilterDetails.atom).getValue()
      : undefined;

    const currentSortBy = sortByFilterDetails
      ? snapshot.getLoadable(sortByFilterDetails.atom).getValue()
      : undefined;

    const modalFiltersArgs = (modalFiltersDetails || []).reduce<
      PurifiedEndpointArgs<EndpointArgs>
    >((accumulator, { atom, payloadKey }) => {
      const currentValue = snapshot.getLoadable(atom).getValue();

      return { ...accumulator, [payloadKey]: currentValue };
    }, {} as PurifiedEndpointArgs<EndpointArgs>);

    const searchFilterArgs = { search: searchValue };

    const sortByFilterArgs = {
      sortingField: currentSortBy?.sortingField,
      sortingType: currentSortBy?.sortingType,
    };

    const releaseSnapshot = snapshot.retain();

    savedSnapshotRef.current = snapshot;

    return async (
      { page }: { page: number },
      ...otherArgs: DropFirst<Parameters<typeof fetchRequest>>
    ) => {
      setCurrentPage(page);

      await fetchRequest(
        {
          ...modalFiltersArgs,
          ...searchFilterArgs,
          ...sortByFilterArgs,
          count,
          page,
        },
        ...otherArgs,
      );

      if (snapshot.isRetained()) {
        releaseSnapshot();
      }
    };
  }, memoizedFetchDependencies);

  // ON CLOSE
  const releaseSnapshotOnClose = savedSnapshot?.retain?.();
  const goToSnapshot = useGotoRecoilSnapshot();
  const resetNotifications = useResetRecoilAtoms(notificationAtoms);

  const onFiltersClose = () => {
    goToSnapshot(savedSnapshot);

    // reset notifications in case snapshot was taken while notifications banner was showing
    resetNotifications();

    if (savedSnapshot.isRetained()) {
      releaseSnapshotOnClose();
    }
  };

  // ON RESET
  const atoms = modalFiltersDetails?.map(({ atom }) => {
    return atom;
  });
  const resetAtoms = useResetRecoilAtoms(atoms);

  const onFiltersReset = async () => {
    resetAtoms();
    await handleFetchRequest({
      page: 1,
    });
  };

  // ON APPLY
  const onFiltersApply = async () => {
    await handleFetchRequest({ page: 1 });
  };

  // ON PAGE # CHANGE
  const onPageChange = async (page: number) => {
    await handleFetchRequest({ page });
  };

  // ON SORT
  const sortByAtom = useMemo(() => {
    return (
      sortByFilterDetails?.atom ||
      getSortByFilterAtomFamily<SortBy<SortingField>>({
        defaultValue: sortByFallbackDefaultValue,
      })(pageName)
    );
  }, [pageName]);

  const [sortBy, setSortBy] = useRecoilState(sortByAtom);

  const onSort = async (args: SortBy<SortingField>) => {
    setSortBy(args);
  };

  // ON SEARCH
  const searchAtom = useMemo(() => {
    return searchFilterDetails?.atom || getSearchFilterAtomFamily(pageName);
  }, [pageName]);

  // uses a double state management with useDebounce as regular debounce without double state causes unexpected delays and overrides
  const [searchValue, setSearchValue] = useRecoilState(searchAtom);

  // NUM FILTERS ACTIVE
  const getNumFiltersActive = useRecoilCallback(({ snapshot }) => {
    return () => {
      const numFiltersActive = (modalFiltersDetails || []).filter(
        ({ atom, defaultValue, payloadKey }) => {
          const currentValue = snapshot.getLoadable(atom).getValue();
          const isFilterUpdated = !isEqual(defaultValue, currentValue);

          const includeFilter: boolean = !excludeFromActiveFiltersCount?.some(
            (keyName) => {
              return keyName === payloadKey;
            },
          );

          return includeFilter && isFilterUpdated;
        },
      ).length;

      return numFiltersActive;
    };
  });

  const numFiltersActive = getNumFiltersActive();

  // AUTO-FETCH ON FIRST LOAD AND UPDATES TO SEARCH OR SORT BY
  useEffect(() => {
    //  remove useAsync because the inLoading state is handled by react-query
    //  and forces unnecessary renders.
    handleFetchRequest({ page: 1 });
  }, [memoizedFetchDependencies, searchValue, sortBy]);

  return {
    currentPage,
    /** Set as the table's `filters` prop value. */
    filtersProps: {
      count: totalCount,
      filtersContent,
      numActive: numFiltersActive,
      onApply: onFiltersApply,
      onClose: onFiltersClose,
      onReset: onFiltersReset,
    },
    /** Allows you to call the provided `fetchRequest` with all the applicable filters applied. Only requires `page`. */
    handleFetchRequest,
    /** Set as the table's `paging` prop value. */
    pagingProps: {
      currentPage,
      onPageChange,
      pageCount: pages,
      total: totalCount,
    },
    records,
    searchProps: {
      columns: [],
      onSearch: setSearchValue,
      value: searchValue,
    },
    setCurrentPage,
    /** Sets the data records that should appear in the table. */
    setRecords,
    /** Sets the total number of data records found. */
    setTotalCount,
    /** Set as the table's `sort` prop value. */
    sortProps: {
      current: sortBy,
      fields: sortOptions,
      onSort,
    },
  };
};
