import styled, { css } from 'styled-components';
import { Cell } from 'gantri-components';

export const StyledCell = styled(Cell)`
  ${({ theme }) => {
    return css`
      [class*='StyledRadioContainer'] {
        padding-bottom: ${theme.dimensions.spacing['.5x']};
      }
    `;
  }}
`;
