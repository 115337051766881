import { Modal } from 'gantri-components';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../api';
import { Job, Machine as JobMachine } from '../../../../api/jobs/jobs.types';
import {
  jobStatuses,
  jobSteps,
  jobTypeOptions,
} from '../../../../constants/options';
import { MachineRepairIssueType } from '../../../../constants/options/options.types';
import { useNotification } from '../../../../hooks/useNotification';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { Content } from './components/content';
import { Footer } from './components/footer';
import {
  RepairJobModalProps,
  RepairJobModalStep,
  repairJobModalSteps,
} from './repair-job-modal.types';
import { useSpinner } from '../../../../hooks';
import { JobDetailsPanelLarge } from '../../../jobs/components/modals/common/job-details-panel-large';
import { FullScreenModalHeader } from '../../../jobs/components/modals/common/full-screen-modal-header';
import { getMachine } from './helpers/get-machine';
import { getJobInventoryRequests } from './helpers/get-job-inventory-requests';
import { JobDetails } from '../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { repairJobModalModalAtoms } from './repair-job-modal.atom';
import { OverviewMachine } from '../../../../api/machines/routes';
import { useResetRecoilAtoms } from '../../../../hooks/use-reset-recoil-atoms';

export const RepairJobModal = (props: RepairJobModalProps) => {
  const {
    job: startingJobData,
    machine: startingMachineData,
    onClose,
    onJobUpdated,
  } = props;

  const resetAtoms = useResetRecoilAtoms(repairJobModalModalAtoms);
  const [notes, setNotes] = useRecoilState(repairJobModalModalAtoms.notes);
  const [repairType, setRepairType] = useRecoilState(
    repairJobModalModalAtoms.repairType,
  );
  const [job, setJob] = useRecoilState(repairJobModalModalAtoms.job);
  const [step, setStep] = useRecoilState(repairJobModalModalAtoms.step);
  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    repairJobModalModalAtoms.inventoryRequests,
  );
  const [selectedIssues, setSelectedIssues] = useRecoilState(
    repairJobModalModalAtoms.selectedIssues,
  );
  const [updateOnClose, setUpdateOnClose] = useRecoilState(
    repairJobModalModalAtoms.updateOnClose,
  );
  const setMaterial = useSetRecoilState(repairJobModalModalAtoms.material);
  const setMachine = useSetRecoilState(repairJobModalModalAtoms.machine);

  const panelJob = useMemo(() => {
    return {
      ...(job || {}),
      machineIssues: selectedIssues,
      notes: repairType ? notes : job?.notes,
    } as Job;
  }, [job, selectedIssues, repairType, notes]);

  const { notifyAxiosError } = useNotification();
  const { onInterceptRequest } = useSpinner();

  const handleUpdateJob = async () => {
    await onInterceptRequest(async () => {
      if (updateOnClose) {
        const issueIds = selectedIssues.map(({ id }) => {
          return id;
        });

        try {
          await jobsApi.updateJob({
            inventoryRequests,
            issues: issueIds,
            jobId: job?.id,
            notes,
          });
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to update job.',
          });
        }
      }
    });
  };

  const handleCloseModal = async () => {
    await handleUpdateJob();

    onClose();

    if (step !== repairJobModalSteps.newRepair) {
      await onJobUpdated();
    }
  };

  useEffect(() => {
    if (!!startingJobData?.notes || !repairType?.description || !job?.notes) {
      return;
    }

    setUpdateOnClose(true);
  }, [inventoryRequests, job?.notes]);

  useEffect(() => {
    const machine = getMachine({ startingJobData, startingMachineData });

    const defaultMachine: Partial<JobMachine> = {
      id: machine?.id,
      name: machine?.name,
      type: machine?.type,
    };

    const defaultJob: Partial<JobDetails> = {
      machine: defaultMachine as JobMachine,
      step: jobSteps.technician,
      type: jobTypeOptions.repair,
    };

    const job = startingJobData || (defaultJob as JobDetails);
    const isJobInProgress = job?.status === jobStatuses.inProgress;

    const defaultStep: RepairJobModalStep = isJobInProgress
      ? repairJobModalSteps.confirmUsedInventory
      : repairJobModalSteps.newRepair;

    const jobInventoryRequests = getJobInventoryRequests({
      startingJobData,
    });

    // init values, this causes nine unnecessary re-renders
    setNotes(job?.notes || '');

    setRepairType({
      description: startingJobData?.description,
      duration: startingJobData?.duration,
      type: startingJobData?.description as MachineRepairIssueType,
    });

    setJob(job);
    setStep(defaultStep);
    setInventoryRequests(jobInventoryRequests);
    setSelectedIssues(startingJobData?.machineIssues || []);
    setMaterial(machine?.material);
    setUpdateOnClose(isJobInProgress);
    setMachine(machine as OverviewMachine);

    return () => {
      resetAtoms();
    };
  }, [startingJobData, startingMachineData]);

  return (
    <Modal
      closeable={false}
      dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
      detailsPanel={
        <JobDetailsPanelLarge
          job={panelJob}
          repairType={repairType}
          sectionsToShow={[
            'machine type',
            'machine',
            'machine issues',
            'inventories',
            'notes',
            'repair type',
          ]}
        />
      }
      detailsPanelWidth="1fr"
      footer={<Footer handleCloseModal={handleCloseModal} />}
      header={
        <FullScreenModalHeader handleCloseModal={handleCloseModal} job={job} />
      }
      isFullScreen
    >
      <Content onClose={handleUpdateJob} />
    </Modal>
  );
};
