import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  CompleteRepairRequest,
  CompleteRepairResponse,
} from './complete-repair.types';

export const completeRepair = (params: CompleteRepairRequest) => {
  const { jobId, ...payload } = params;

  return axios.put<CompleteRepairResponse>(
    `${getCoreApiUrl('repairs')}/${jobId}/complete`,
    payload,
  );
};
