import * as yup from 'yup';
import { NumberSchema } from 'yup';
import {
  required,
  validateRequiredNumber,
} from '../../../../helpers/validators';
import { Material } from '../../../../constants/parts';

export interface ChangeSpoolFormDef {
  material: Material;
  spoolWeight?: number;
  state: 'new' | 'used';
}

export const ChangeSpoolSchema: yup.SchemaOf<ChangeSpoolFormDef> = yup
  .object()
  .shape({
    material: required() as yup.SchemaOf<Material>,
    spoolWeight: validateRequiredNumber() as unknown as NumberSchema,
    state: required() as yup.SchemaOf<'new' | 'used'>,
  });
