import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { Button } from 'gantri-components';
import { PrintRfidTagFooterProps } from './step-print-rfid-tag-footer.types';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { usePassOrFailJob } from '../../step-complete-checklist/complete-checklist-footer/hooks/use-pass-or-fail-job';

export const PrintRfidTagFooter: FC<PrintRfidTagFooterProps> = (props) => {
  const { handleCloseModal } = props;

  const job = useRecoilValue(completePrintHarvestModalAtoms.job);
  const offlineForMaintenance = useRecoilValue(
    completePrintHarvestModalAtoms.offlineForMaintenance,
  );

  const { onPass, processing } = usePassOrFailJob({
    handleCloseModal,
    job,
  });

  return (
    <Button
      processing={processing}
      size={fullScreenModalFooterButtonSize}
      text={offlineForMaintenance ? 'Next' : 'Done'}
      onClick={onPass}
    />
  );
};
