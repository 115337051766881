/* eslint-disable   sort-keys-fix/sort-keys-fix */

export const materials = {
  fabbOpaque: 'ColorFabb HT Opaque',
  polymakerOpaque: 'Polymaker Sandable Opaque',
  fabbTranslucent: 'ColorFabb GPLA Translucent',
  polymakerTranslucent: 'Polymaker Translucent',
  na: 'N/A',
} as const;

export type Material = typeof materials[keyof typeof materials];

export const isTranslucentMaterial = (type: Material) => {
  return (
    type === materials.fabbTranslucent ||
    type === materials.polymakerTranslucent
  );
};

export const isOpaqueMaterial = (type: Material) => {
  return type === materials.polymakerOpaque || type === materials.fabbOpaque;
};

export const materialsFilterOptions = Object.values(materials).filter((op) => {
  return op !== materials.na;
});

export const getMaterialsByOpacity = (material: Material): Material[] => {
  if (isOpaqueMaterial(material)) {
    return [materials.fabbOpaque, materials.polymakerOpaque];
  }

  return [materials.polymakerTranslucent, materials.fabbTranslucent];
};

export const materialsOptions = Object.values(materials)
  .filter((op) => {
    return op !== materials.na;
  })
  .map((op) => {
    return {
      value: op,
      labelText: op,
    };
  });
