import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  MaintenanceCheckerRequest,
  MaintenanceCheckerResponse,
} from './maintenance-checker.types';

export const maintenanceChecker = (args: MaintenanceCheckerRequest) => {
  return axios
    .get<MaintenanceCheckerResponse>(
      `${getCoreApiUrl('machines')}/maintenance-checker`,
      { params: args },
    )
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.response.data;
    });
};
