import { FC } from 'react';
import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { StepPrepareMachineFooterProps } from './step-prepare-machine-footer.types';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { spoolChangedTypes } from '../../../../../start-job/components/start-print-print-job-modal/start-print-print-job-modal.constants';
import { checkAgainstFailingChecklistItems } from '../../../../../helpers/check-against-failing-checklist-items';
import { usePassOrFailJob } from '../../step-complete-checklist/complete-checklist-footer/hooks/use-pass-or-fail-job';
import { printHarvestSteps } from '../../../complete-print-harvest-modal.constants';
import { useChangeMachineSpool } from '../../../../../../../../../api/machines/routes';
import { addedTypeMapper } from './adapter';

export const PrepareMachineFooter: FC<StepPrepareMachineFooterProps> = (
  props,
) => {
  const { handleCloseModal } = props;

  const spoolWeight = useRecoilValue(
    completePrintHarvestModalAtoms.spoolWeight,
  );

  const materialChanged = useRecoilValue(
    completePrintHarvestModalAtoms.materialChanged,
  );

  const spoolChanged = useRecoilValue(
    completePrintHarvestModalAtoms.spoolChanged,
  );

  const checklist = useRecoilValue(completePrintHarvestModalAtoms.checklist);
  const setStep = useSetRecoilState(completePrintHarvestModalAtoms.step);
  const job = useRecoilValue(completePrintHarvestModalAtoms.job);

  const partFailed = checkAgainstFailingChecklistItems(checklist, (item) => {
    return item.failStatusShouldFailPart;
  });

  const { onChangeSpool } = useChangeMachineSpool();

  const needToHaveMaterial =
    spoolChanged === spoolChangedTypes.yesNew ||
    spoolChanged === spoolChangedTypes.yesUsed;

  const disableContinue =
    !spoolWeight || (needToHaveMaterial && !materialChanged);

  const { onFail, processing } = usePassOrFailJob({
    handleCloseModal,
    job,
  });

  const onContinue = async () => {
    await onChangeSpool({
      addedType: addedTypeMapper[spoolChanged],
      machineIds: [job.machine?.id],
      material: materialChanged,
      spoolWeight,
    });

    if (partFailed) {
      await onFail();

      return;
    }

    setStep(printHarvestSteps.printRfigTag);
  };

  return (
    <Button
      disabled={disableContinue}
      processing={processing}
      size={fullScreenModalFooterButtonSize}
      text="Continue"
      onClick={onContinue}
    />
  );
};
