import React from 'react';
import {
  Cell,
  RadioList,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import { ModalContentHeading } from '../../../../../../../jobs/components/modals/common/modal-content-heading';
import { materialsOptions } from '../../../../../../../../constants/parts';
import { repairJobModalModalAtoms } from '../../../../repair-job-modal.atom';

export const PrepareMachineContent = () => {
  // type as string to avoid RadioList onSelect callback complaining
  const [material, setMaterial] = useRecoilState<string>(
    repairJobModalModalAtoms.material,
  );
  const [spoolWeight, setSpoolWeight] = useRecoilState(
    repairJobModalModalAtoms.spoolWeight,
  );

  return (
    <Stack gap="4x">
      <ModalContentHeading titleText="Prepare machine" />

      <Cell gap="x">
        <Typography text="Current Material" variant="h5" />

        <RadioList
          idProperty="value"
          items={materialsOptions}
          labelProperty="labelText"
          value={material}
          onSelected={setMaterial}
        />
      </Cell>

      <Cell gap="x">
        <Typography text="Current Spool Weight" variant="h5" />
        <TextField
          debounce={250}
          placeholder="Input weight"
          required
          rightIcon={<Typography color="t2" text="g" />}
          type="number"
          value={String(spoolWeight)}
          onTextChange={(value) => {
            setSpoolWeight(Number(value));
          }}
        />
      </Cell>

      <Cell gap="x">
        <Typography text="Clean print bed" variant="h5" />
        <Typography text="Please ensure the print bed is ready for the next print." />
      </Cell>
    </Stack>
  );
};
