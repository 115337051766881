import { Button, Conditional } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { checkAgainstFailingChecklistItems } from '../../../../../helpers/check-against-failing-checklist-items';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completePrintHarvestModalAtoms } from '../../../complete-print-harvest-modal.atoms';
import { JobFailedFooterProps } from './job-failed-footer.types';
import { printHarvestSteps } from '../../../complete-print-harvest-modal.constants';

export const JobFailedFooter = (props: JobFailedFooterProps) => {
  const { handleCloseModal } = props;

  const setStep = useSetRecoilState(completePrintHarvestModalAtoms.step);
  const checklist = useRecoilValue(completePrintHarvestModalAtoms.checklist);
  const machinePrintHoursExceeded = useRecoilValue(
    completePrintHarvestModalAtoms.machinePrintHoursExceeded,
  );

  const shouldTakeMachineOffline =
    machinePrintHoursExceeded ||
    checkAgainstFailingChecklistItems(
      checklist,
      ({ failStatusShouldTakeMachineOffline }) => {
        return failStatusShouldTakeMachineOffline;
      },
    );

  return (
    <Conditional
      condition={shouldTakeMachineOffline}
      Fallback={
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Done"
          onClick={async () => {
            await handleCloseModal();
          }}
        />
      }
    >
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Next"
        onClick={() => {
          setStep(printHarvestSteps.machineOffline);
        }}
      />
    </Conditional>
  );
};
