import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { NewRepairContent } from './components/new-repair-content';
import { ConfirmUsedInventoryContent } from './components/confirm-used-inventory-content';
import { ContentProps } from './content.types';
import { RepairSummaryContent } from './components/repair-summary-content';
import { ConfirmCompleteRepairContent } from './components/confirm-complete-repair-content';
import { repairJobModalSteps } from '../../repair-job-modal.types';
import { PrepareMachineContent } from './components/prepare-machine-content';
import {
  repairJobModalModalAtoms,
  repairJobModalModalSelectors,
} from '../../repair-job-modal.atom';

export const Content = (props: ContentProps) => {
  const { onClose } = props;

  const step = useRecoilValue(repairJobModalModalAtoms.step);
  const isJobInProgress = useRecoilValue(
    repairJobModalModalSelectors.isJobInProgress,
  );
  const openIssues = useRecoilValue(repairJobModalModalSelectors.openIssues);
  const [repairType, setRepairType] = useRecoilState(
    repairJobModalModalAtoms.repairType,
  );
  const [notes, setNotes] = useRecoilState(repairJobModalModalAtoms.notes);
  const [selectedIssues, setSelectedIssues] = useRecoilState(
    repairJobModalModalAtoms.selectedIssues,
  );
  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    repairJobModalModalAtoms.inventoryRequests,
  );
  const setUpdateOnClose = useSetRecoilState(
    repairJobModalModalAtoms.updateOnClose,
  );

  switch (step) {
    case repairJobModalSteps.newRepair:
      return (
        <NewRepairContent
          notes={notes}
          openIssues={openIssues}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setNotes={setNotes}
          setRepairType={setRepairType}
          setSelectedIssues={setSelectedIssues}
          onClose={onClose}
        />
      );

    case repairJobModalSteps.confirmUsedInventory:
      return (
        <ConfirmUsedInventoryContent
          inventoryRequests={inventoryRequests}
          setInventoryRequests={setInventoryRequests}
        />
      );

    case repairJobModalSteps.prepareMachine:
      return <PrepareMachineContent />;

    case repairJobModalSteps.confirmCompleteRepair:
      return (
        <ConfirmCompleteRepairContent
          isJobInProgress={isJobInProgress}
          notes={notes}
          openIssues={openIssues}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setNotes={setNotes}
          setSelectedIssues={setSelectedIssues}
          setUpdateOnClose={setUpdateOnClose}
          onClose={onClose}
        />
      );

    case repairJobModalSteps.repairSummary:
      return <RepairSummaryContent issues={selectedIssues} />;

    default:
      return null;
  }
};
