import { SpoolChanged } from '../../../../../start-job/components/start-print-print-job-modal/start-print-print-job-modal.types';
import { spoolChangedTypes } from '../../../../../start-job/components/start-print-print-job-modal/start-print-print-job-modal.constants';

export const spoolChangeOptions: {
  labelHtmlText: string;
  value: SpoolChanged;
}[] = [
  {
    labelHtmlText: 'No spool change',
    value: spoolChangedTypes.noChange,
  },
  {
    labelHtmlText: 'Yes, <strong>used</strong> spool added',
    value: spoolChangedTypes.yesUsed,
  },
  {
    labelHtmlText: 'Yes, <strong>new</strong> spool added',
    value: spoolChangedTypes.yesNew,
  },
];
